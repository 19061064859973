









import { GridOptions, GridReadyEvent, IServerSideDatasource, SelectionChangedEvent } from '@ag-grid-community/core';
import { Vue, Component, Prop, VModel, Watch } from 'vue-property-decorator';
import { IKeyValue } from '@/interfaces/key-value.interface';
import { GridHelper } from '@/utils/helpers/grid-helper';

@Component({})
export default class AgGridWrapper extends Vue {
  themeClass = 'ag-theme-alpine ag-vuetify';

  defaultGridOptions: GridOptions = {
    domLayout: 'autoHeight',
    defaultColDef: {
      flex: 1,
      sortable: true,
      resizable: true,
      cellClassRules: {
        excelTotals: (params) => !!params.node.rowPinned,
      },
    },

    suppressCellSelection: true,
    suppressRowClickSelection: true,
    rowSelection: 'multiple',
    pagination: false,

    excelStyles: GridHelper.getDefaultExcelStyles(),

    localeTextFunc: (key: string, defaultValue: string): string => {
      const translate = `${this.$t(`agGrid.${key}`)}`;
      return translate || defaultValue;
    },

    onGridReady: this.onGridReady,
    onSelectionChanged: this.onSelectionChanged,
  };

  selected: IKeyValue[] | null | undefined = [];

  @VModel()
  grid!: GridReadyEvent;

  @Prop()
  loading!: boolean;

  @Prop({ default: () => {} })
  agGridOptions!: Partial<GridOptions>;

  @Prop()
  defaultSort!: { colId: string; sort: string }[];

  @Prop({ default: true })
  pagination!: boolean;

  @Prop({ default: 30 })
  paginationPageSize!: number;

  @Prop()
  serverSidePagination!: boolean;

  @Prop()
  serverSideDatasource!: IServerSideDatasource;

  @Prop({ default: true })
  clickableRow!: boolean;

  @Prop({ default: false })
  enableCellTextSelection!: boolean;

  @Prop()
  summaryData!: IKeyValue<string | number>[];

  @Watch('loading')
  onChangeLoading(value: boolean): void {
    if (!this.grid) {
      return;
    }

    if (value) {
      this.grid.api.showLoadingOverlay();
    } else {
      this.grid.api.hideOverlay();
    }
  }

  @Watch('summaryData')
  onSummaryDataChange(data: IKeyValue<string | number>[]): void {
    if (!this.grid) {
      return;
    }

    this.grid.api.setPinnedBottomRowData(data);
  }

  onGridReady(params: GridReadyEvent): void {
    this.grid = params;
    this.$emit('grid-ready', params);

    if (params.api) {
      if (this.loading) {
        params.api.showLoadingOverlay();
      }

      if (this.defaultSort) {
        params.columnApi.applyColumnState({ state: this.defaultSort });
      }

      if (this.summaryData) {
        params.api.setPinnedBottomRowData([...this.summaryData]);
      }

      if (this.serverSidePagination) {
        if (this.serverSideDatasource) {
          params.api.setServerSideDatasource(this.serverSideDatasource);
        } else {
          throw new Error('To use server side pagination, its necessary provide a server side datasource');
        }
      }
    }
  }

  onSelectionChanged(event: SelectionChangedEvent): void {
    this.selected = this.gridOptions.api && this.gridOptions.api.getSelectedRows();

    this.$emit('selection-changed', event, this.selected);
  }

  get gridOptions(): GridOptions {
    const options = {
      ...this.defaultGridOptions,
      ...this.agGridOptions,
      pagination: this.pagination,
      paginationPageSize: this.paginationPageSize,
      rowClass: this.clickableRow ? 'ag-clickable-row' : '',
      enableCellTextSelection: this.enableCellTextSelection,
    };

    if (this.serverSidePagination) {
      options.rowModelType = 'serverSide';
      options.serverSideStoreType = 'partial';
      options.cacheBlockSize = this.paginationPageSize;
      options.maxBlocksInCache = 1;
    }

    return options;
  }

  get showSelected(): boolean {
    return !!(this.selected && this.selected.length);
  }
}
