import { ExcelStyle, ValueFormatterParams, ValueGetterParams, ColDef } from '@ag-grid-community/core';
import { i18n } from '@/i18n-setup';
import { DateHelper } from '@/utils/helpers/date-helper';
import { ExcelDataTypeEnum } from '@/enums/excel-data-type.enum';

export abstract class GridHelper {
  /**
   * Get column value in ag-grid ColumnDef
   * @param params ValueGetterParams
   * @param formatter CallableFunction
   * @returns T
   */
  static valueGetter<T = string>(params: ValueGetterParams, formatter?: CallableFunction): T {
    const data = GridHelper.getData(params);
    if (formatter) {
      return formatter(data);
    }
    return data;
  }

  /**
   * Get default ExcelStyle to be used in ag-grid
   * @returns ExcelStyle[]
   */
  static getDefaultExcelStyles(): ExcelStyle[] {
    return [
      {
        id: 'dateISO',
        dataType: ExcelDataTypeEnum.DateTime,
        numberFormat: {
          format: DateHelper.getLocaleDateFormat(i18n.locale),
        },
      },

      {
        id: 'excelTotals',
        font: { bold: true },
        interior: {
          color: '#f5f5f5',
          pattern: 'Solid',
          patternColor: '#f5f5f5',
        },
      },

      {
        id: 'header',
        font: { bold: true },
        interior: {
          color: '#CCCCCC',
          pattern: 'Solid',
          patternColor: '#CCCCCC',
        },
      },
    ];
  }

  /**
   * Get selection colDef properties
   * @returns ColDef
   */
  static getSelectionColDef(def?: ColDef): ColDef {
    return {
      colId: 'selection',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      sortable: false,
      minWidth: 37,
      maxWidth: 37,
      resizable: false,
      pinned: true,
      lockPosition: true,
      ...def,
    };
  }

  private static getData(params: ValueGetterParams | ValueFormatterParams) {
    const colId = params.column.getColDef()?.field || params.column.getColId();

    if (colId === '0') {
      throw new Error('Define the "field" attribute in column definition');
    }

    return params.data && params.data[colId];
  }
}
